<template>
  <div>
    <v-form ref="form">
      <v-layout row wrap align-center class="ma-0 d-flex justify-center">
        <template
          v-for="(field, index) in fields"
        >
          <v-flex
            :key="`${field.type || ''}_${field.model || ''}_${index}`" :style="field.style"
            :class="['drag-item', field.class]" align-self-center
          >
            <template v-if="field.type === 'TITLE'">
              <v-card-title> {{ field.text }} </v-card-title>
            </template>
            <template v-if="field.type === 'DIVIDER'">
              <v-divider></v-divider>
            </template>
            <template v-if="field.type === 'TEXT'">
              <v-text-field 
                v-model="modelObj[field.model]"
                outlined
                dense
                :rules="field.rules"
                :label="field.label" 
                :placeholder="field.placeholder" 
                :disabled="field.disabled"
                :readonly="field.readonly"
              ></v-text-field>
            </template>
            <template v-if="field.type === 'TEXTAREA'">
              <v-textarea 
                v-model="modelObj[field.model]"
                outlined
                dense
                :label="field.label" 
                :rules="field.rules"
                :placeholder="field.placeholder" 
                :disabled="field.disabled"
                :readonly="field.readonly"
                :rows="field.rows"
                no-resize
                auto-grow
              ></v-textarea>
            </template>
            <template v-if="field.type === 'CHECKBOX'">
              <v-checkbox 
                v-model="modelObj[field.model]"
                outlined
                dense
                :label="field.label" 
                :placeholder="field.placeholder" 
                :disabled="field.disabled"
                :readonly="field.readonly">
              </v-checkbox>
            </template>
            <template v-if="field.type === 'RADIO'">
              <v-card-subtitle class="pa-0 ma-0">{{ field.label }}</v-card-subtitle>
              <v-radio-group row v-model="modelObj[field.model]" :disabled="field.disabled" mandatory>
                <v-radio v-for="(option, index) in field.selectItems" :key="index" :label="option.text" :value="option.value"></v-radio>
              </v-radio-group>
            </template>
            <template v-if="field.type === 'SELECT'">
              <template v-if="field.selectType === 'MODULE'">
                <v-autocomplete
                  v-model="modelObj[field.model]"
                  :items="lookupModuleItems[field.model]"
                  outlined
                  dense
                  :rules="field.rules"
                  :item-text="(val) => field.selectModule === 'users' ? (`${val.name} ${(val.role && val.role.name) ? '(' + val.role.name + ')' : ''}`) : `${val.name}`"
                  item-value="_id"
                  :label="field.label" 
                  :placeholder="field.placeholder" 
                  :disabled="field.disabled"
                  :multiple="field.isMultiselect"
                  :readonly="field.readonly">
                </v-autocomplete>
              </template>
              <template v-if="field.selectType === 'CUSTOM'" >
                <v-autocomplete
                  v-model="modelObj[field.model]"
                  :items="field.selectItems"
                  outlined
                  :rules="field.rules"
                  dense
                  :multiple="field.isMultiselect"
                  :label="field.label" 
                  :placeholder="field.placeholder" 
                  :disabled="field.disabled"
                  :readonly="field.readonly">
                </v-autocomplete>
              </template>
            </template>
            <template v-if="field.type === 'PASSWORD'">
              <v-text-field
                v-model="modelObj[field.model]"
                outlined
                dense
                :rules="field.rules"
                :append-icon="field.show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="field.show ? 'text' : 'password'"
                :label="field.label" 
                :placeholder="field.placeholder" 
                :disabled="field.disabled"
                hint="At least 8 characters"
                counter
                @click:append="field.show = !field.show"
              ></v-text-field>
            </template>
            <template  v-if="field.type === 'NUMBER'">
              <v-text-field
                v-model="modelObj[field.model]"
                type="number" 
                outlined
                dense
                :rules="field.rules"
                :label="field.label" 
                :placeholder="field.placeholder" 
                :disabled="field.disabled"
                :readonly="field.readonly">
              </v-text-field>
            </template>
            <template v-if="field.type === 'OTP'"></template>
            <template v-if="field.type === 'DATE_PICKER'">
              <v-menu v-model="field.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="modelObj[field.model]"
                    :label="field.label"
                    outlined
                    dense
                    :rules="field.rules"
                    hide-details
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
              <v-date-picker v-model="modelObj[field.model]" @input="field.menu = false"></v-date-picker>
            </v-menu>
            </template>
            <template v-if="field.type === 'TIME_PICKER'">
              <v-menu ref="menu"
                v-model="field.menu" 
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                :rules="field.rules"
                offset-y 
                max-width="290px" 
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="time" label="Picker in menu" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
              <v-time-picker v-if="field.menu" v-model="time" full-width @click:minute="$refs.menu.save(time)"></v-time-picker>
            </v-menu>
            </template>
            <template v-if="field.type === 'DATETIME_PICKER'"></template>
            <template v-if="field.type === 'COLOR_PICKER'"></template>
          </v-flex>
        </template>
      </v-layout>
    </v-form>
  </div>
</template>
<script>
export default {
  props: ['value', 'fields'],
  data () {
    return {
      modelObj: {},
      lookupModuleItems: {},
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  watch: {
    value () {
      this.modelObj = this.value || {}
    },
    modelObj: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>
